import React from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  makeStyles,
  useMediaQuery,
  IconButton,
  Box,
} from "@material-ui/core/"
import { useTheme } from "@material-ui/styles"
import { Button, Title } from "gatsby-theme-material-foundry"
import CloseIcon from "@material-ui/icons/Close"
import MyForm, { JoinStartup } from "./idea-form"

const useStyles = makeStyles(theme => ({
  imageHover: {
    "& :hover": {
      cursor: "pointer",
    },
  },
  root: {
    "& label.Mui-focused": {
      color: theme.palette.black.main,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.black.main}`,
      },
    },
  },
  "& input:valid:focus + fieldset": {
    borderColor: theme.palette.black.main,
  },
}))

export default function FormDialog() {
  const [open, setOpen] = React.useState(false)
  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        size="lg"
        onClick={handleClickOpen}
      >
        Send inn ide
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Skjema</DialogTitle>
        <DialogContent>
          <MyForm handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export function CampusDialog() {
  const [open, setOpen] = React.useState(false)
  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        size="lg"
        onClick={handleClickOpen}
      >
        Send inn idé
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Skjema</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <ul>
              <li>Kontaktinforamsjon</li>

              <li>Bilde av Campus</li>
              <li>
                Infrastruktur
                <ul>
                  <li> Bergen -> studentinkubator</li>
                  <li> Bergen -> 3dPrinter</li>
                </ul>
              </li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" simple>
            Avslutt
          </Button>
          <Button onClick={handleClose} color="primary">
            Send Inn
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export function SendInnIde() {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const [open, setOpen] = React.useState(false)
  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        size="lg"
        onClick={handleClickOpen}
        ariaLabel="send inn ide"
      >
        send Inn idé
      </Button>
      <Dialog
        fullScreen={fullScreen}
        disableBackdropClick
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Title black variant="h4">
              Send inn ide
            </Title>

            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <MyForm handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export function JoinStartupDialog() {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const [open, setOpen] = React.useState(false)
  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <Button
        simple
        color="primary"
        style={{ color: "white" }}
        size="lg"
        onClick={handleClickOpen}
        ariaLabel="bli med på team"
      >
        Bli med i et team
      </Button>
      <Dialog
        fullScreen={fullScreen}
        disableBackdropClick
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Title black variant="h4">
              Send inn forespørsel
            </Title>

            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <JoinStartup handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export function ImageDialog({ title, children, url }) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <div onClick={handleClickOpen} className={classes.imageHover}>
        {children}
      </div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Title black variant="h4">
              {title}
            </Title>

            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <img src={url} alt={title} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
